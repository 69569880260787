body {
  margin : 0px 
}

.App {
  background-color: #5d5c61;
  margin: 0;
  padding: 0;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

@media (max-width: 600px){
  .app{
    background-color: #5d5c61;
    background-position: center center;
    background-size: cover;
    position: relative;
    align-items: flex-end;
    justify-content: center;
  }
  .row-width{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.text{
  font-size: calc(15px + 1.2vw);
  top: 30%;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.text-header-index{
  font-size: calc(24px + 1.2vw);
  display: flex;
  color: white;
  text-shadow: 2px 2px 4px #000000;
  justify-content: center;
  position: absolute;
}

.index-background{
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  justify-content: center;
  margin: 5px;
}

.index-background svg{
  width: calc(50px + 8vw);
  height: calc(30px + 8vw);
  margin: 5px 30px 5px 30px;
  transition: transform .2s;
}

.index-background-icon:hover{
  transform: scale(1.05);
}

.back-icon{
  margin: 5px;
  position: absolute;
  color: white;
}

.back-icon:hover{
  transform: scale(1.05);
}

.container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container-index{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.text-title{
  color: white;
  text-align: center;
  /* text-decoration: underline aqua; */
  padding-top: 3px;
  padding-right: 15%;
  padding-left: 15%;
}

.text-header{
  color: #dcdcdc;
  text-align: center;
  padding-top: 3px;
  padding-right: 15%;
  padding-left: 15%;
}

.text-detail{
  color: white;
  text-align: left;
  padding-top: 3px;
  padding-right: 15%;
  padding-left: 15%;
}

.text-profile{
  color: white;
  text-align: justify;
  padding-right: 15%;
  padding-left: 15%;
}

.work-container{
  padding-right: 15%;
  padding-left: 15%;
  padding-bottom: 30px;
}

.catalog{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  text-align: left;
  padding-right: 15%;
  padding-left: 15%;
  padding-bottom: 15px;
  text-align: center;
  color: white;
}

.volex{
  position: relative;
  padding: 3px;
  margin:  4% 30px 15% 30px;
  height: 200px;
  width: 350px;
}

.nanoguard{
  margin:  4% 30px 15% 30px;
  position: relative;
  padding: 3px;
  height: 200px;
  width: 350px;
}

.nippon{
  position: relative;
  padding: 3px;
  margin:  4% 30px 15% 30px;
  height: 200px;
  width: 350px;
}

.product-images{
  transition: transform .2s;
  border-radius: 15px;
}

.product-images:hover{
  transform: scale(1.05);
}

.catalog-products{
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  color: #dcdcdc;
}

.row-images{
  height: 200px;
  width: 280px;
  max-width: 100%;
  transition: transform .2s;
  margin: 20px;
  border-radius: 5%;
}

.row-images:hover{
  transform: scale(1.05);
}


.image-carousel{
  height: 200px;
  width: 320px;
  max-width: 100%;
  border-radius: 25px
}

.card{
  border-radius: 4px;
  background: #f5f5f5;
  width: 300px;
  color: #666;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  margin: 10px;
}

.card:hover{
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.card-body {
  padding: 15px;
}
 
.card-title {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}
 
.card-text {
  text-align: left;
  margin-top: 10px;
  word-wrap: break-word;
}

.slick-slide > div {
  margin: 0 25px;
}
 
.slick-list {
  margin: 0 -10px;
}
 
.slick-slide *:focus{
  outline: none;
}

.img-card {
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
}

.product-row{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image-card{
  height: 200px;
  width: 300px;
  max-width: 100%;
}
