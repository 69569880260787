.row{
    background-color: black;
    position:relative;
    bottom: 0;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 10px;
}

.column{
    float: left;
    width: 50%;
    display: flex;
    justify-content: center;
}

.row:after{
    content: "";
    display: table;
    clear: both;
}

@media screen and (max-width: 600px) {
    .row{
        width: 100%;
    }
}

.column a{
    color: aliceblue;
}

.footerlink :hover{
    color: aqua;
    transition: 200ms ease-in;
}

.copyright{
    text-align: center;
    color: aliceblue;
}
